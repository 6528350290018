// Add babel-polyfill to provide ES2015 support in IE
// See: http://babeljs.io/docs/usage/polyfill/
import 'babel-polyfill';

//Styles
import './master.scss';


// Libs
require('!script-loader!../bower_components/jquery/dist/jquery.js');
require('!script-loader!../bower_components/angular/angular.min.js');
require('!script-loader!../bower_components/angular-animate/angular-animate.js');
require('!script-loader!../bower_components/angular-aria/angular-aria.js');
require('!script-loader!../bower_components/angular-cookies/angular-cookies.js');
require('!script-loader!../bower_components/angular-messages/angular-messages.js');
require('!script-loader!../bower_components/angular-material/angular-material.js');
require('!script-loader!../bower_components/angular-resource/angular-resource.js');
require('!script-loader!../bower_components/angular-sanitize/angular-sanitize.js');
require('!script-loader!../bower_components/moment/min/moment-with-locales.min.js');
require('!script-loader!../bower_components/humanize-duration/humanize-duration.js');
require('!script-loader!../bower_components/angular-timer/dist/angular-timer.js');
require('!script-loader!../bower_components/angular-translate/angular-translate.js');
require('!script-loader!../bower_components/angular-translate-loader-partial/angular-translate-loader-partial.js');
require('!script-loader!../bower_components/fullcalendar/dist/fullcalendar.js');
require('!script-loader!../bower_components/angular-ui-router/release/angular-ui-router.js');
require('!script-loader!../bower_components/mobile-detect/mobile-detect.js');
require('!script-loader!../bower_components/perfect-scrollbar/js/perfect-scrollbar.js');
require('!script-loader!../bower_components/toastr/toastr.js');
require('!script-loader!../bower_components/jquery-ui/jquery-ui.js');
require('!script-loader!../bower_components/angular-ui-sortable/sortable.js');
require('!script-loader!../bower_components/Sortable/Sortable.js');
require('!script-loader!../bower_components/Sortable/ng-sortable.js');
require('!script-loader!../bower_components/jqueryui-touch-punch/jquery.ui.touch-punch.min.js');
require('!script-loader!../bower_components/angular-moment-picker/dist/angular-moment-picker.js');
require('!script-loader!../bower_components/angular-material-data-table/dist/md-data-table.js');

require('!script-loader!../bower_components/socket.io-client/socket.io.js');
require('!script-loader!../bower_components/angular-ellipsis/src/angular-ellipsis.js');
require('!script-loader!../bower_components/angular-fixed-table-header/src/fixed-table-header.js');
require('!script-loader!../bower_components/ng-tags-input/ng-tags-input.min.js');


// App Source
require('./core/core.module.js');
require('./main/invoices/list/zip_pending_dialog/zip_pending_dialog.js');
require('./main/invoices/list/zip_download_dialog/zip_download_dialog.js');
require('./main/invoices/form/profile-alert/profile-alert.controller.js');
require('./main/company/edit/edit.module.js');
require('./main/company/edit/add-edit-integration-project/add-edit-integration-project.controller.js');
require('./main/company/edit/add-edit-user/add-edit-user.controller.js');
require('./main/company/edit/add-edit-integration/add-edit-integration.controller.js');
require('./main/calendar/calendar.module.js');
require('./main/calendar/dialogs/event-form/event-form-dialog.controller.js');
require('./quick-panel/quick-panel.module.js');
require('./quick-panel/tabs/chat/chat-tab.controller.js');
require('./main/tokens/list/list.module.js');
require('./main/tokens/list/list.controller.js');
require('./main/tokens/form/form.module.js');
require('./main/tokens/form/form.controller.js');
require('./main/time-tracking/time-tracking.module.js');
require('./main/time-tracking/add-edit-time-tracking/add-edit-time-tracking.controller.js');
require('./main/time-tracker/time-tracker.module.js');
require('./main/time-tracker/screens/screens.controller.js');
require('./main/stories/list/list.module.js');
require('./main/stories/list/list.controller.js');
require('./main/stories/form/form.module.js');
require('./main/stories/form/form.controller.js');
require('./main/registry-sales/list/list.module.js');
require('./main/registry-sales/list/list.controller.js');
require('./main/registry-sales/items-receipts/items.controller.js');
require('./main/registry-sales/jpk-fa/jpk-fa.controller.js');
require('./main/registry-sales/items/items.controller.js');
require('./main/receipts/list/list.module.js');
require('./main/receipts/list/list.controller.js');
require('./main/receipts/invoices/invoices.controller.js');
require('./main/receipts/crete-invoice/crete-invoice.controller.js');
require('./main/receipts/items/items.controller.js');
require('./main/products/list/list.module.js');
require('./main/products/list/list.controller.js');
require('./main/products/form/form.module.js');
require('./main/products/form/form.controller.js');
require('./main/projects/form/form.module.js');
require('./main/projects/wizard/wizard.controller.js');
require('./main/projects/show/show.module.js');
require('./main/projects/show/show.controller.js');
require('./main/projects/list/list.module.js');
require('./main/projects/list/list.controller.js');
require('./main/projects/form/form.controller.js');
require('./main/projects/clone-dialog/clone-dialog.controller.js');
require('./main/pages/unauthorized/unauthorized.module.js');
require('./main/pages/unauthorized/unauthorized.controller.js');
require('./main/pages/switch-company/switch-company.module.js');
require('./main/pages/switch-company/switch-company.controller.js');
require('./main/pages/regulations/regulations.module.js');
require('./main/pages/regulations/regulations.controller.js');
require('./main/pages/privacy-policy/privacy-policy.module.js');
require('./main/pages/privacy-policy/privacy-policy.controller.js');
require('./main/pages/package-alert/package-alert.controller.js');
require('./main/pages/new-version/new-version.controller.js');
require('./main/pages/language_webbrowser/language_webbrowser.controller.js');
require('./main/pages/image-preview/image-preview.controller.js');
require('./main/pages/invite_tax_office/invite_tax_office.controller.js');
require('./main/pages/gdpr_info/gdpr_info.js');
require('./main/pages/cookie/cookie.module.js');
require('./main/pages/cookie/cookie.controller.js');
require('./main/pages/calendar_empty/calendar_empty.js');
require('./main/pages/blockade-company/blockade-company.js');
require('./main/online-sales/list/list.module.js');
require('./main/online-sales/list/list.controller.js');
require('./main/online-sales/items/items.controller.js');
require('./main/online-sales/invoices/invoices.controller.js');
require('./main/files/files.module.js');
require('./main/files/add-edit-file/add-edit-file.controller.js');
require('./main/knowledge/knowledge.module.js');
require('./main/knowledge/add-edit-page/add-edit-page.controller.js');
require('./main/knowledge/add-edit-directory/add-edit-directory.controller.js');
require('./main/invoices/list/list.module.js');
require('./main/invoices/payments/payments.controller.js');
require('./main/invoices/success_save/success_save.controller.js');
require('./main/invoices/list/list.controller.js');
require('./main/invoices/form-correction/form-correction.module.js');
require('./main/invoices/form-correction/form-correction.controller.js');
require('./main/invoices/form-edit/form-edit.module.js');
require('./main/invoices/form-edit/form-edit.controller.js');
require('./main/invoices/add-payment/add-payment.controller.js');
require('./main/invoices/form/form.module.js');
require('./main/invoices/form/form.controller.js');
require('./main/errors/500/error-500.module.js');
require('./main/errors/500/error-500.controller.js');
require('./main/errors/404/error-404.module.js');
require('./main/errors/404/error-404.controller.js');
require('./main/errors/403/error-403.module.js');
require('./main/errors/403/error-403.controller.js');
require('./main/dashboard/dashboard.module.js');
require('./main/dashboard/fv/fv-dashboard.controller.js');
require('./main/contractors/list/list.module.js');
require('./main/contractors/list/list.controller.js');
require('./main/company/payment-back/payment-back.module.js');
require('./main/company/payment-back/payment-back.controller.js');
require('./main/contractors/form/form.module.js');
require('./main/contractors/form/form.controller.js');
require('./main/company/edit/payment.service.js');
require('./main/company/edit/edit.controller.js');
require('./main/company/new/new.module.js');
require('./main/company/new/new.controller.js');
require('./main/company/my-list/my-list.module.js');
require('./main/company/my-list/my-list.controller.js');
require('./main/cash-operations/list/list.module.js');
require('./main/cash-operations/list/list.controller.js');
require('./main/company/buy-wizard/buy-wizard.module.js');
require('./main/company/buy-wizard/buy-wizard.controller.js');
require('./main/cash-operations/form/form.module.js');
require('./main/cash-operations/form/form.controller.js');
require('./main/auth/user-edit/user-edit.module.js');
require('./main/auth/user-edit/user-edit.controller.js');
require('./main/auth/reset-password/reset-password.module.js');
require('./main/auth/reset-password/reset-password.controller.js');
require('./main/auth/register/register.module.js');
require('./main/auth/register/register.controller.js');
require('./main/auth/login-quick/login-quick.module.js');
require('./main/auth/login-quick/login-quick.controller.js');
require('./main/auth/invitation/invitation.module.js');
require('./main/auth/invitation/invitation.controller.js');
require('./main/auth/login/login.module.js');
require('./main/auth/login/login.controller.js');
require('./main/auth/forgot-password/forgot-password.module.js');
require('./main/auth/forgot-password/forgot-password.controller.js');
require('./main/auth/activation/activation.module.js');
require('./main/auth/activation/activation.controller.js');
require('./main/calendar/summary/summary-calendar.service.js');
require('./main/calendar/summary/summary-calendar.controller.js');
require('./main/calendar/activity/activity-calendar.service.js');
require('./main/calendar/activity/activity-calendar.controller.js');
require('./main/agile/tasks-calendar/tasks-calendar.module.js');
require('./main/agile/tasks-calendar/tasks-calendar.controller.js');
require('./main/agile/statuses/statuses.module.js');
require('./main/agile/statuses/statuses.controller.js');
require('./main/agile/backlog/backlog.module.js');
require('./main/agile/sprint-close/sprint-close.controller.js');
require('./main/agile/sprint-clone/sprint-clone.controller.js');
require('./main/agile/sidenav/sidenav.service.js');
require('./main/agile/scrumboard-ticket/scrumboard-ticket.module.js');
require('./main/agile/scrumboard-ticket/scrumboard-ticket.controller.js');
require('./main/agile/history/history.module.js');
require('./main/agile/history/history.controller.js');
require('./main/agile/scrumboard/scrumboard.module.js');
require('./main/agile/scrumboard/scrumboard.controller.js');
require('./main/agile/backlog/backlog.controller.js');
require('./main/agile/add-edit-ticket/add-edit-ticket.controller.js');
require('./directives/inline-inputs/inline-texteditor/inline-texteditor.directive.js');
require('./directives/inline-inputs/inline-text/inline-text.directive.js');
require('./directives/inline-inputs/inline-textarea/inline-textarea.directive.js');
require('./directives/inline-inputs/inline-select/inline-select.directive.js');
require('./directives/inline-inputs/inline-datepicker/inline-datepicker.directive.js');
require('./directives/inline-inputs/inline-chips/inline-chips.directive.js');
require('./directives/inline-inputs/inline-autocomplete/inline-autocomplete.directive.js');
require('./directives/inline-inputs/inline-chips-edit-button/inline-chips-edit-button.directive.js');
require('./core/directives/ms-widget/ms-widget.directive.js');
require('./core/directives/ms-timeline/ms-timeline.js');
require('./core/directives/ms-splash-screen/ms-splash-screen.directive.js');
require('./core/directives/ms-search-bar/ms-search-bar.directive.js');
require('./core/directives/ms-sidenav-helper/ms-sidenav-helper.directive.js');
require('./core/directives/ms-scroll/ms-scroll.directive.js');
require('./core/directives/ms-responsive-table/ms-responsive-table.directive.js');
require('./core/directives/ms-random-class/ms-random-class.directive.js');
require('./core/directives/ms-navigation/ms-navigation.directive.js');
require('./core/directives/ms-nav/ms-nav.directive.js');
require('./core/directives/ms-card/ms-card.directive.js');
require('./core/directives/ms-form-wizard/ms-form-wizard.directive.js');
require('./main/time-tracking/time-tracking.controller.js');
require('./main/ticket/ticket.module.js');
require('./main/ticket/ticket.controller.js');
require('./main/sample/sample.module.js');
require('./main/sample/sample.controller.js');
require('./main/files/files.controller.js');
require('./main/knowledge/knowledge.controller.js');
require('./main/knowledge/knowledge.controller.js');
require('./main/invoices/invoice.service.js');
require('./main/home/home.module.js');
require('./main/home/home.controller.js');

require('./main/dashboard/dashboard.service.js');
require('./main/dashboard/dashboard.controller.js');
require('./main/dashboard/main/dashboard-main.component.ts');
require('./main/dashboard/main/dashboard-main.service.ts');
require('./main/dashboard/main/notifications/notifications.component.ts');
require('./main/dashboard/main/preview/preview.component.ts');
require('./main/dashboard/main/preview/task/task-preview.component.ts');
require('./main/dashboard/main/preview/knowledge-page/knowledge-page.component.ts');

require('./main/calendar/drag-drop.directive.js');
require('./main/calendar/calendar.service.js');
require('./main/calendar/calendar.controller.js');
require('./directives/zip-code/zip-code.directive.js');
require('./directives/uploadFile/postMultipart.service.js');
require('./directives/uploadFile/fileread.directive.js');
require('./directives/uploadFile/fileModel.directive.js');
require('./directives/time/time.directive.js');
require('./directives/on-size-changed/on-size-changed.directive.js');
require('./directives/textarea-editor/textarea-editor.directive.js');
require('./directives/textarea-editor/textarea-sanitizer.service.ts');
require('./directives/repaint-on-scroll/repaint-on-scroll.directive.js');
require('./directives/related-ticket/related-ticket.directive.js');
require('./directives/ms-confirm/ms-confirm.directive.js');
require('./directives/logo-sqr/logo-sqr.directive.js');
require('./directives/loading-image-indicator/loading-image-indicator.directive.js');
require('./directives/input-clear/input-clear.directive.js');
require('./directives/capitalize/capitalize.directive.js');
require('./directives/autofocus/autofocus.directive.js');
require('./directives/bind-html-compile/bind-html-compile.directive.js');
require('./directives/autocomplete-required/autocomplete-required.directive.js');
require('./directives/active-link/active-link.directive.js');
require('./core/theming/fuse-theming.provider.js');
require('./core/theming/fuse-theming.config.js');
require('./core/theming/fuse-themes.constant.js');
require('./core/theming/fuse-palettes.constant.js');
require('./core/theming/fuse-generator.factory.js');
require('./core/services/ms-utils.service.js');
require('./core/services/api-resolver.service.js');
require('./core/filters/tag.filter.js');
require('./core/filters/basic.filter.js');
require('./core/directives/highlight.directive.js');
require('./core/config/fuse-config.provider.js');
require('./services/trans.service.js');
require('./services/toast.service.js');
require('./services/table.service.js');
require('./services/socket.service.js');
require('./services/projects.service.js');
require('./services/projectFilters.service.js');
require('./services/menu.service.js');
require('./services/form.service.js');
require('./services/files.service.js');
require('./services/dialog.service.js');
require('./services/content.interceptor.js');
require('./services/content-parser.service.js');
require('./services/clipboard.service.js');
require('./services/auth.service.js');
require('./services/auth-interceptor.service.js');
require('./services/apiErrors.service.js');
require('./toolbar/toolbar.module.js');
require('./toolbar/toolbar.controller.js');
require('./quick-panel/quick-panel.controller.js');
require('./navigation/navigation.module.js');
require('./navigation/navigation.controller.js');
require('!script-loader!../bower_components/moment/locale/pl.js');
require('./index.module.js');
require('./main/main.controller.js');
require('./filters/global.filter.js');
require('./filters/datetime.filter.js');
require('./core/core.run.js');
require('./core/core.config.js');
require('./resourceJson.service.js');
require('./index.run.js');
require('./index.route.js');
require('./index.controller.js');
require('./index.constants.js');
require('./index.config.js');
require('./index.api.js');
require('./api.json.js');
require('./api.http.js');
require('./main/projects/wizard/wizard.module.js');
require('./templateCacheHtml.js');

// Bootstrap the app
require('./polyfills');
require('./bootstrap');
